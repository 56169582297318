import React, { SVGProps } from 'react';

export default function TwilioLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100"
      height="100"
      fill="#08aa4f"
      viewBox="0 0 490.23 277.97"
      {...props}
    >
      <g>
        <path
          d="M490.23,108.8V35.58C490.23,15.96,474.27,0,454.65,0H375.7L35.59,0.02C15.97,0.02,0.01,15.98,0.01,35.6L0,92.22h91.01
                v-0.04h2.36V42.06c0-17.24,14.03-31.26,31.26-31.26h240.96c1.89,0,3.73,0.17,5.53,0.49c14.61,2.61,25.74,15.4,25.74,30.75v57.91
                c0,18.55-15.09,33.63-33.64,33.63H35.58C15.96,133.58,0,149.54,0,169.16v73.22c0,19.62,15.96,35.58,35.58,35.58h89.05v-0.01h241.03
                v0.02h88.99c19.62,0,35.58-15.96,35.58-35.58v-56.13l-93.38,0.01v49.64c0,17.24-14.03,31.26-31.26,31.26H124.34
                c-4.95-0.05-9.63-1.25-13.78-3.35c-10.19-5.16-17.19-15.72-17.19-27.9v-57.91c0-14.3,8.96-26.53,21.57-31.39
                c3.75-1.45,7.82-2.24,12.07-2.24h327.64C474.27,144.38,490.23,128.42,490.23,108.8z"
        />
        <polygon
          points="180.47,196.73 180.47,186.97 149.34,186.97 136.03,186.97 136.03,223.61 149.34,223.61 149.78,223.61
                149.78,211.04 179.16,211.04 179.16,201.52 149.78,201.52 149.78,196.73 	"
        />
        <path
          d="M196.58,217.84h26.56l3.75,5.77H242l-23.56-36.65h-15.71l-24.78,36.65h14.73L196.58,217.84z M208.07,200.16l2.01-3.15
                l1.87,3.15l1.45,2.25c0.98,1.5,1.92,3,2.9,4.51l1.45,2.2h-15.71L208.07,200.16z"
        />
        <path
          d="M270.68,224.19c14.87,0,24.03-3.76,27.54-11.26c0.52-1.03,0.94-2.62,1.27-4.79h-14.51c-0.05,0.61-0.23,1.12-0.52,1.45
                c-1.78,2.72-6.33,4.08-13.7,4.08c-3.2,0-6.05-0.42-8.58-1.27c-2.58-0.8-4.37-2.06-5.44-3.8c-0.56-0.94-0.84-2.01-0.84-3.28
                c0-1.36,0.28-2.49,0.8-3.37c1.93-3.34,7.13-5.12,14.08-5.12c6.76,0,11.36,1.78,13.18,4.04c0.42,0.52,0.75,1.03,0.94,1.64h14.5
                c-0.19-1.64-0.56-3.1-1.12-4.37c-3.89-7.93-13-11.87-27.4-11.87c-12.9,0-21.72,3.2-26.42,9.62c-1.87,2.58-2.81,5.68-2.81,9.33
                c0,3.75,0.98,7.04,2.95,9.8C248.96,221.13,257.68,224.19,270.68,224.19z"
        />
        <polygon
          points="351.27,196.63 351.27,186.97 305.99,186.97 305.99,223.61 351.69,223.61 351.69,214 319.55,214 319.55,209.87
                350.46,209.87 350.46,200.72 319.55,200.72 319.55,196.63 	"
        />
        <path
          d="M202.71,53.79l-24.77,36.65h14.73l3.91-5.78h26.55l3.75,5.78h15.11l-23.56-36.65H202.71z M202.06,75.94l6.01-8.96
                l2.02-3.15l1.87,3.15l1.46,2.25c0.98,1.5,1.92,3,2.9,4.51l1.45,2.2H202.06z"
        />
        <polygon
          points="136.03,53.8 136.03,90.44 149.34,90.44 149.78,90.44 149.78,77.86 179.16,77.86 179.16,68.34 149.78,68.34
                149.78,63.55 180.47,63.55 180.47,53.8 149.34,53.8 	"
        />
        <path
          d="M270.78,63.64c6.75,0,11.35,1.78,13.18,4.04c0.42,0.52,0.75,1.03,0.94,1.64h14.49c-0.18-1.64-0.56-3.1-1.12-4.36
                c-0.49-0.99-1.05-1.92-1.7-2.79c-0.65-0.87-1.38-1.67-2.19-2.41c-1.22-1.11-2.62-2.09-4.21-2.92c-1.06-0.56-2.2-1.05-3.42-1.48
                c-4.28-1.51-9.57-2.27-15.87-2.27h0l0,0c-6.45,0-11.88,0.8-16.28,2.4c-4.41,1.6-7.78,4.01-10.14,7.23
                c-1.87,2.57-2.81,5.68-2.81,9.33c0,3.75,0.99,7.04,2.95,9.8c1.37,1.91,3.16,3.52,5.37,4.83c4.87,2.89,11.78,4.34,20.72,4.34l0,0h0
                c7.43,0,13.44-0.94,18.03-2.82c4.59-1.88,7.76-4.7,9.51-8.45c0.26-0.52,0.49-1.17,0.71-1.97c0.21-0.8,0.4-1.74,0.56-2.82h0h-14.51
                c-0.05,0.61-0.23,1.13-0.51,1.46c-1.78,2.71-6.34,4.07-13.7,4.07c-3.2,0-6.06-0.42-8.59-1.26c-2.57-0.8-4.36-2.06-5.44-3.81
                c-0.56-0.93-0.85-2.01-0.85-3.28c0-1.35,0.29-2.49,0.8-3.37C258.62,65.42,263.82,63.64,270.78,63.64z"
        />
        <polygon
          points="351.27,63.46 351.27,53.8 305.99,53.8 305.99,90.44 351.69,90.44 351.69,80.82 319.55,80.82 319.55,76.69
                350.47,76.69 350.47,67.54 319.55,67.54 319.55,63.46 	"
        />
      </g>
    </svg>
  );
}
